<template>
    <div
        v-if="organizationId && userCanManageOrganization"
        id="add-employee-form"
    >
        <FormErrors
            :errors="errorList"
            @dismiss-errors="onDismissErrors"
        />

        <form
            ref="formRef"
            novalidate
            @submit.prevent="onSubmit"
        >
            <BaseFormField
                :required="true"
                :errors="[errors.firstName]"
            >
                <template #label>
                    <label for="first_name">Voornaam</label>
                </template>

                <template #default>
                    <BaseInput
                        id="first_name"
                        v-model="firstName"
                        type="text"
                        placeholder="Vul de voornaam van de medewerker in"
                    />
                </template>
            </BaseFormField>

            <BaseFormField
                :errors="[errors.preposition]"
            >
                <template #label>
                    <label for="preposition">Tussenvoegsel</label>
                </template>

                <template #default>
                    <BaseInput
                        id="preposition"
                        v-model="preposition"
                        type="text"
                        placeholder="Vul tussenvoegsel in"
                    />
                </template>
            </BaseFormField>

            <BaseFormField
                :required="true"
                :errors="[errors.lastName]"
            >
                <template #label>
                    <label for="last_name">Achternaam</label>
                </template>

                <template #default>
                    <BaseInput
                        id="last_name"
                        v-model="lastName"
                        type="text"
                        placeholder="Vul de achternaam van de medewerker in"
                    />
                </template>
            </BaseFormField>

            <BaseFormField
                :required="true"
                :errors="[errors.userFunction]"
            >
                <template #label>
                    <label for="user_function">Functie</label>
                </template>

                <template #default>
                    <BaseInput
                        id="user_function"
                        v-model="userFunction"
                        type="text"
                        placeholder="Vul de functie van de medewerker in"
                    />
                </template>
            </BaseFormField>

            <BaseFormField
                :required="true"
                :errors="[errors.email]"
            >
                <template #label>
                    <label for="email">E-mailadres</label>
                </template>

                <template #default>
                    <BaseInput
                        id="last_name"
                        v-model="email"
                        type="email"
                        placeholder="Vul het e-mailadres van de medewerker in"
                        :disabled="!!employee?.functionEmail"
                    />
                </template>
            </BaseFormField>

            <!-- 2024-05-08: request by TLN to remove phone number for now (TLN-739)
            <BaseFormField
                :errors="[errors.phoneLandline]"
            >
                <template #label>
                    <label for="phone">Telefoonnummer (vast)</label>
                </template>

                <template #default>
                    <BaseInput
                        id="phone_landline"
                        v-model="phoneLandline"
                        type="tel"
                        placeholder="Vul het vaste telefoonnummer van de medewerker in"
                    />
                </template>
            </BaseFormField> -->

            <!-- 2024-05-08: request by TLN to remove phone number for now (TLN-739)
            <BaseFormField
                :errors="[errors.phoneMobile]"
            >
                <template #label>
                    <label for="phone">Telefoonnummer (mobiel)</label>
                </template>

                <template #default>
                    <BaseInput
                        id="phone_mobile"
                        v-model="phoneMobile"
                        type="tel"
                        placeholder="Vul het mobiele telefoonnummer van de medewerker in"
                    />
                </template>
            </BaseFormField>
            -->

            <BaseFormField>
                <template #default>
                    <div class="form__actions">
                        <BaseButton
                            type="button"
                            class="base-button--transparent-dark"
                            size="medium"
                            @click="$emit('dismiss')"
                        >
                            Annuleer
                        </BaseButton>

                        <BaseButton
                            type="submit"
                            size="medium"
                            :is-loading="isLoading"
                        >
                            Voeg toe
                        </BaseButton>
                    </div>
                </template>
            </BaseFormField>
        </form>
    </div>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useAuthStore } from '~/store/auth';
import { useModalStore } from '~/store/modal';
import { useGtm } from '@gtm-support/vue-gtm';

const authStore = useAuthStore();
const modalStore = useModalStore();

const props = defineProps({
    successRedirectPath: {
        type: String,
        default: ''
    },
    employee: {
        type: Object,
        default: null
    },
    organizationId: {
        type: String,
        default: '',
        required: true
    }
});

defineEmits(['dismiss']);

const globalErrors = {
    fallback: 'Er is iets misgegaan bij het uitnodigen van de medewerker. Probeer het later nog eens.'
};

// Set validation schema
const validationSchema = object({
    firstName: string().required('Voornaam is verplicht'),
    lastName: string().required('Achternaam is verplicht'),
    preposition: string(),
    userFunction: string().required('Functie is verplicht'),
    email: string().required('E-mailadres is verplicht').email('Dit is helaas geen geldig e-mailadres'),
    // phoneLandline: string().length(10, 'Het telefoonnummer moet 10 cijfers lang zijn'),
    // phoneMobile: string().length(10, 'Het telefoonnummer moet 10 cijfers lang zijn'),
});

const { errors, defineField, handleSubmit } = useForm({
    validationSchema,
    initialValues: {
        firstName: props.employee?.userFirstName ?? '',
        lastName: props.employee?.userLastName ?? '',
        preposition: props.employee?.userPreposition ?? '',
        userFunction: props.employee?.functionName ?? '',
        email: props.employee?.functionEmail ?? '',
        // phoneLandline: props.employee?.functionPhoneLandline ?? '',
        // phoneMobile: props.employee?.functionPhoneMobile ?? '',
    }
});

// Define models
const [firstName] = defineField('firstName');
const [lastName] = defineField('lastName');
const [preposition] = defineField('preposition');
const [userFunction] = defineField('userFunction');
const [email] = defineField('email');
// const [phoneLandline] = defineField('phoneLandline');
// const [phoneMobile] = defineField('phoneMobile');

// Generic error list
const errorList = ref([]);
const isLoading = ref(false);

const onSubmit = handleSubmit(values => {
    submitDataToApi(values);
});

const submitDataToApi = async function(formData) {
    errorList.value = [];
    isLoading.value = true;

    try {
        await useLaravelApi(
            `/user/${toValue(user).id}/organization/${props.organizationId}/invite-employee`,
            {
                method: 'POST',
                data: sanitizeData(formData)
            }
        ).then(() => {
            useGtm().trackEvent({
                event: 'form_submit_custom',
                formHandle: 'addEmployeeForm'
            });

            if (props.successRedirectPath) {
                modalStore.deactivateAllModals();
                return navigateTo(props.successRedirectPath);
            }
        }).catch(error => {
            setErrorMessage(error.response?.data?.code);
        });

        isLoading.value = false;

    } catch (error) {
        setErrorMessage();
        isLoading.value = false;
    }
};

function sanitizeData(formData) {
    const data = {};

    if (props.employee) {
        data.userId = props.employee.userId;
    }

    return {
        ...data,
        ...formData
    };
}

function setErrorMessage(errorCode) {
    const errorMessage = globalErrors[errorCode] ? globalErrors[errorCode] : globalErrors.fallback;
    errorList.value.push(errorMessage);
}

function onDismissErrors() {
    errorList.value = [];
}

const user = computed(() => {
    return authStore.userModel;
});

const userCanManageOrganization = computed(() => {
    return toValue(user).canManageOrganization(props.organizationId);
});
</script>

<style lang="less">
.form__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}
</style>
